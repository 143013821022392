.form-field.form-field__vertical label.checkbox, .checkbox {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  margin-bottom: 1rem;

  input[type=checkbox] {
    margin-left: 0;
    margin-top: 0;
    appearance: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    position: relative;

    margin-right: 8px;

    background: #E7E7E7;
    border-radius: 0;
    box-shadow: none;

    + * {
      flex: 1;
    }

    &[checked] {
      background: #3CAFC9;

      &::before {
        background-image: url('../images/skillsCheckedIcon.png');
        background-size: 20px;
      }
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    &:disabled {
      cursor: default;
    }
  }
}

.form-field-content:has(.checkbox) {
  margin-top: 16px;
}
