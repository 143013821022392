/* class naming using BEMCSS -> https://getbem.com/introduction/*/

:root {
    --black: #000000;
    --dark-100: #303F46;
    --dark-200: #435963;
    --neutral-100: #E9F1F4;
    --white: #FFF;
}

.color--dark-100 {
    color: var(--dark-100);
}

.color--dark-200 {
    color: var(--dark-200);
}
